import { useState, useEffect } from 'react';
import { load } from './config';

function ConfigLoader({ ready, loading }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [config, setConfig] = useState(null);

  const loadConfig = async () => {
    const newConfig = await load();

    if (newConfig) {
      setIsLoaded(true);
      setConfig(newConfig);
    }
  };

  useEffect(() => {
    loadConfig();
  }, []);

  if (!isLoaded) return loading ? loading() : null;
  return ready(config);
}

export default ConfigLoader;
