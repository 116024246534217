import React from 'react';

import { Spin } from 'antd';

import { LoaderContainer } from './styles';

function Loader() {
  return (
    <LoaderContainer>
      <Spin size="large" />
    </LoaderContainer>
  );
}

export default Loader;
