import React from 'react';
import { NotFoundContainer } from './styles';
import logo from '../../assets/images/MHC-logo.png';
import rejected from '../../assets/images/rejected_icon.png';

function NotFound() {
  return (
    <div>
      <img src={logo} className="App-logo" alt="logo" />
      <br />
      <NotFoundContainer>
        <div>
          <img src={rejected} alt="not-found" className="not-found" />
          <h3>Not Found</h3>
        </div>
      </NotFoundContainer>
    </div>
  );
}

export default NotFound;
