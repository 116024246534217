import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { Button, HomeContainer, StyledUserAddOutlined, StyledUserOutlined, WelcomeMessage } from './styles';

import logo from '../../assets/images/MHC-logo.png';
import imgUserBlue from '../../assets/images/new_user_blue.png';
import imgUserLight from '../../assets/images/new_user_light.png';
import imgMobileLight from '../../assets/images/mobile_light.png';
import imgMobileBlue from '../../assets/images/mobile_blue.png';

// function useQuery() {
//   const { search } = useLocation();
//   return React.useMemo(() => new URLSearchParams(search), [search]);
// }

function Home() {
  // const query = useQuery();

  const [transactionId, setTransactionId] = useState(null);

  useEffect(() => {
    let newTransactionId = Cookies.get('transactionId');

    if (!newTransactionId) {
      newTransactionId = uuidv4();
      Cookies.set('transactionId', newTransactionId, { expires: 1 });
    }

    setTransactionId(newTransactionId);
  }, []);

  const [buttonImage, setButtonImage] = useState({
    newCustomerIsLight: false,
    verifyIsLight: false
  });

  return (
    <div>
      <img src={logo} className="App-logo" alt="logo" />
      <WelcomeMessage>
        <h3>Welcome!</h3>
      </WelcomeMessage>
      <HomeContainer>
        <Button
          as={Link}
          // to={`/new/${query.get('id')}`}
          to={{ pathname: '/new' }}
          state={{ transactionId }}
          onMouseEnter={() =>
            setButtonImage((state) => {
              return { ...state, newCustomerIsLight: true };
            })
          }
          onMouseLeave={() =>
            setButtonImage((state) => {
              return { ...state, newCustomerIsLight: false };
            })
          }
        >
          <StyledUserAddOutlined isLight={buttonImage.newCustomerIsLight} />
          <span>New Customer</span>
        </Button>

        <Button
          as={Link}
          to="/verification"
          onMouseEnter={() =>
            setButtonImage((state) => {
              return { ...state, verifyIsLight: true };
            })
          }
          onMouseLeave={() =>
            setButtonImage((state) => {
              return { ...state, verifyIsLight: false };
            })
          }
        >
          <StyledUserOutlined isLight={buttonImage.verifyIsLight} />
          <span>Return Customer</span>
        </Button>
      </HomeContainer>
    </div>
  );
}

export default Home;
