import styled from 'styled-components';
import { UserAddOutlined, UserOutlined } from '@ant-design/icons';

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: pre-line;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const WelcomeMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  margin-bottom: 2em;
  width: 99%;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  & h3 {
    color: #060045;
    font-family: 'GTSectraDisplay';
    font-weight: 400;
    font-size: 58px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    text-align: center !important;
  }

  & span {
    font-family: 'PTRootUI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba(6, 0, 69, 0.4);
  }
`;

export const Button = styled.button`
  cursor: pointer;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 0.5em;
  width: 36vmin;
  height: 43vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  white-space: pre-line;

  font-family: 'GTSectraDisplay';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: #060045;

  img {
    margin-bottom: 1rem;
    max-height: 28%;
    width: auto;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    width: 80vmin;
    height: 30vmin;
    font-size: 17px;
    border-radius: 20px;

    img {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }

  &:hover {
    background-color: #070342;
    color: #fff;
  }

  .blue {
    color: #283fff;
  }
`;

export const StyledUserOutlined = styled(UserOutlined)`
  ${(props) => (props.isLight ? `color: #fffff;` : `color: #4054ff;`)}
  font-size: 50px;
  ${(props) => (props.isLight ? `background: #57575d;` : `background: #f9f9ff;`)}
  border-radius: 50%;
  padding: 30px;
  display: inline-block;
  margin-bottom: 20px;
`;

export const StyledUserAddOutlined = styled(UserAddOutlined)`
  ${(props) => (props.isLight ? `color: #fffff;` : `color: #4054ff;`)}
  font-size: 50px;
  ${(props) => (props.isLight ? `background: #57575d;` : `background: #f9f9ff;`)}
  border-radius: 50%;
  padding: 30px;
  display: inline-block;
  margin-bottom: 20px;
`;
