import styled from 'styled-components';

export const UploadContainer = styled.div`
  .content {
    width: 50%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 992px) {
      width: 100%;
    }
  }

  label {
    font-family: 'PTRootUI';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #060045;
  }

  .ant-upload-text {
    font-family: 'PTRootUI';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #060045;
  }

  .ant-upload-hint {
    font-family: 'PTRootUI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #06004545;
  }

  .ant-upload-drag {
    padding: 22px 36px 22px 36px;
  }

  .ant-btn {
    margin-right: 8px;
    border-radius: 4px;
    font-family: 'PTRootUI';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    border: 0px;
  }

  .ant-form-item-explain-error {
    font-family: 'PTRootUI';
    font-weight: 500;
    font-size: 14px;
  }

  .btn-clear {
    background: #282273;
  }

  .btn-upload {
    background: linear-gradient(82deg, #283fff 0%, #6663ff 100%);
  }

  .ant-upload-drag:hover {
    border-color: #283fff !important;
  }
`;
