import { Alert, Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header, Loader } from '../../components';
import { OrdersContainer } from './styles';
import config from '../../components/configuration/config';

function Orders() {
  const { customerId } = useParams();
  const [dataSource, setDataSource] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const navigate = useNavigate();

  const getOrders = () => {
    setIsLoading(true);
    fetch(`${config.MHC_APP_API_URL}/api/Transaction/GetOpenOrderTransactions?customerId=${customerId}`, {
      method: 'GET',
      headers: {
        Authorization: `${config.MHC_APP_API_KEY}`
      }
    })
      .then((res) => res.text())
      .then((result) => {
        setIsLoading(false);
        if (JSON.parse(result).length > 0) {
          const orderNumbers = JSON.parse(result);
          const newDatasource = orderNumbers.map((item) => {
            return { orderNumber: item };
          });
          setDataSource(newDatasource);
        } else {
          navigate('/verification/success');
        }
      });
  };

  const columns = [
    {
      key: 'orderNumber',
      title: 'Order No.',
      dataIndex: 'orderNumber',
      render: (text) => text
    }
  ];

  const handleSelectedColumn = (rowKeys) => {
    setSelectedRowKeys(rowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectedColumn
  };

  const verifyOrder = () => {
    setIsLoading(true);

    // Extract order numbers from selectedRowKeys
    const orderNumbers = selectedRowKeys.map((orderNumber) => orderNumber);

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: config.MHC_APP_API_KEY
      },
      body: JSON.stringify(orderNumbers)
    };

    fetch(`${config.MHC_APP_API_URL}/api/Transaction/UpdateOrderTransactionsWorkflowStatus`, options)
      .then((res) => res.text())
      .then(
        (result) => {
          setIsLoading(false);
          if (result === 'UpdateStatusOrder is executed on updating the workflow statuses of multiple order transactions') {
            navigate('/verification/success');
          } else {
            setErrors(result);
          }
        },
        (error) => {
          setErrors(error);
          setIsLoading(false);
        }
      );
  };

  const handleCloseAlert = () => {
    setErrors(null);
    handleSelectedColumn([]);
  };

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <OrdersContainer>
      <Header title="Security Verification" />
      {errors && <Alert message={errors} type="error" closable onClose={handleCloseAlert} />}

      <div className="content">
        <div className="content-header">Select your orders to verify:</div>

        <Table
          rowSelection={rowSelection}
          size="small"
          bordered
          columns={columns}
          dataSource={dataSource}
          style={{ marginTop: 20 }}
          pagination={{ position: ['topRight'] }}
          rowKey="orderNumber"
        />

        <Button type="primary" onClick={verifyOrder} disabled={selectedRowKeys.length === 0 || isLoading}>
          Verify
        </Button>
      </div>

      {isLoading && <Loader />}
    </OrdersContainer>
  );
}

export default Orders;
