import styled from 'styled-components';

export const VerficationContainer = styled.div`
  .content {
    width: 50%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 992px) {
      width: 100%;
    }
  }

  .ant-tabs-nav-list {
    display: flex;
    justify-content: center;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    justify-content: center;
    align-items: center;
    display: flex;
    color: #060045;
    font-family: 'GTSectraDisplay';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #283fff;
  }

  .ant-tabs-tab-btn:focus {
    color: #283fff;
  }

  .ant-tabs-ink-bar {
    background-color: #283fff;
  }

  .ant-btn-primary {
    height: 40px;
    border: 0;
    background: linear-gradient(82deg, #283fff 0%, #6663ff 100%);
  }

  .ant-btn-primary[disabled] {
    opacity: 0.5;
    color: #fff;
  }

  .ant-btn-primary[disabled]:hover {
    background: linear-gradient(82deg, #283fff 0%, #6663ff 100%);
  }

  .ant-input {
    height: 45px;
    width: 100%;
    font-size: 14px;
    padding-top: 0.5rem;
    font-family: 'PTRootUI';
  }

  .ant-form-vertical .ant-form-item-label > label {
    font-family: 'PTRootUI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #060045;
  }

  .anticon svg {
    color: #d9d9d9;
    font-size: 17px;
  }

  .ant-alert-error {
    background: #e76c6c;
    border-radius: 4px;
    padding: 13px;
    margin-bottom: 1em;

    .ant-alert-message {
      color: #ffffff;
      font-family: 'PTRootUI';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .resend-btn {
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      border: 0;
      background-color: transparent;
      cursor: pointer;
      color: #283fff;
    }

    img {
      margin-left: 5px;
    }
  }

  .switch-link {
    font-family: 'PTRootUI';
    font-size: 14px;
    line-height: 18px;
    color: #060045;
    margin-top: 48px;

    button {
      border: 0;
      background: transparent;
      padding: 0px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
