import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Upload, Button, Alert } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Header, Loader } from '../../components';
import { UploadContainer } from './styles';
import config from '../../components/configuration/config';

const { Dragger } = Upload;

function UploadDocument() {
  const { customerId } = useParams();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(null);

  const handleClear = () => {
    form.resetFields();
  };

  const handleCloseAlert = () => {
    setShowAlert(null);
  };

  const handleFinish = async (values) => {
    setShowAlert(null);

    const totalSize = values.documents.fileList.reduce((accumulator, object) => {
      return accumulator + object.size;
    }, 0);

    if (totalSize > 5000000) {
      return setShowAlert({
        type: 'error',
        message: 'File must not exceed 5MB'
      });
    }

    // eslint-disable-next-line prefer-const
    let formData = new FormData();
    const files = values.documents.fileList;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      formData.append(`file${i}`, files[i].originFileObj);
    }

    setIsLoading(true);

    const response = await fetch(`${config.MHC_APP_API_URL}/api/File/UploadFile?customerId=${customerId}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: config.MHC_APP_API_KEY
      }
    });

    setIsLoading(false);

    if (response.status === 200) {
      form.resetFields();

      return setShowAlert({
        type: 'success',
        message: 'File successfully uploaded'
      });
    }

    return setShowAlert({
      type: 'error',
      message: 'Failed to upload file, please try again'
    });
  };

  return (
    <UploadContainer>
      <Header title="Additional Documents" subTitle="Upload the required documents below. Accepted formats: PNG, JPEG, and PDF" />

      <div className="content">
        {showAlert && (
          <>
            <Alert type={showAlert.type} description={showAlert.message} closable onClose={handleCloseAlert} />
            <br />
          </>
        )}

        <Form name="basic" form={form} onFinish={handleFinish}>
          <Form.Item label="Documents" name="documents" rules={[{ required: true, message: 'Please add a document' }]}>
            <Dragger
              multiple
              beforeUpload={() => {
                return false;
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ color: '#283FFF' }} />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p>
            </Dragger>
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Button htmlType="button" type="primary" size="large" onClick={handleClear} className="btn-clear">
              Clear
            </Button>
            <Button size="large" type="primary" htmlType="submit" icon={<UploadOutlined />} className="btn-upload">
              Upload Documents
            </Button>
          </Form.Item>
        </Form>
      </div>

      {isLoading && <Loader />}
    </UploadContainer>
  );
}

export default UploadDocument;
