import styled from 'styled-components';

export const OrdersContainer = styled.div`
  .content {
    @media (max-width: 992px) {
      width: 100%;
    }

    .content-header {
      font-family: 'PTRootUI';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #060045;
    }

    .ant-btn-primary {
      width: 100%;
    }
  }
`;
