import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { HeaderContainer } from './styles';

import logo from '../../assets/images/MHC-logo.png';

function Header({ title, subTitle }) {
  const location = useLocation();

  return (
    <div>
      <img src={logo} className="App-logo" alt="logo" />

      {location.pathname !== '/upload' && (
        <Link to="/" className="App-Home-Link">
          Back to Home
        </Link>
      )}

      <HeaderContainer>
        <h3>{title}</h3>
        <span>{subTitle}</span>
      </HeaderContainer>
    </div>
  );
}

export default Header;
