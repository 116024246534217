import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  margin-bottom: 2em;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  & h3 {
    font-family: 'GTSectraDisplay';
    font-style: normal;
    font-weight: 400;
    font-size: 58px;
    line-height: 72px;
    text-align: center !important;
    color: #060045;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  & span {
    font-family: 'PTRootUI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba(6, 0, 69, 0.4);
  }
`;
