const configfile = '/settings.json';
const config = {};

export default config;

function load() {
  return fetch(configfile)
    .then((result) => result.json())
    .then((newconfig) => {
      Object.keys(config).forEach((key, index) => {
        delete config[key];
      });
      Object.keys(newconfig).forEach((key, index) => {
        config[key] = newconfig[key];
      });

      return config;
    });
}
export { load };
