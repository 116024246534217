import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SumsubWebSdk from '@sumsub/websdk-react';
import Cookies from 'js-cookie';
import { Loader } from '../../components';
import config from '../../components/configuration/config';

import { NewCustomerContainer } from './styles';

import logo from '../../assets/images/MHC-logo.png';

function NewCustomer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { transactionId } = location.state;

  const [isloading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const getAccessToken = () => {
    setIsLoading(true);
    setAccessToken(null);

    fetch(`${config.MHC_APP_API_URL}/api/KYC/AccessToken/${transactionId}`, {
      headers: {
        Authorization: config.MHC_APP_API_KEY
      }
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setAccessToken(result.token);
          setIsLoading(false);
        },
        (error) => {
          setAccessToken(null);
          setIsLoading(false);
        }
      );
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  const handleExpiration = (data) => {
    // console.log('EXPIRATION', data);
  };

  const handleMessage = (type, payload) => {
    // console.log('onMessage', type, payload);

    if (type === 'idCheck.applicantStatus') {
      const { reviewStatus } = payload;

      if (reviewStatus === 'completed') {
        Cookies.remove('transactionId');
        navigate('/verification/success');
      }
    }
  };

  const handleError = (data) => {
    // console.log('ERROR', data);
  };

  return (
    <div>
      <img src={logo} className="App-logo" alt="logo" />
      <Link to="/" className="App-Home-Link">
        Back to Home
      </Link>
      <NewCustomerContainer>
        {accessToken && (
          <SumsubWebSdk
            accessToken={accessToken}
            expirationHandler={handleExpiration}
            // config={config}
            // options={options}
            onMessage={handleMessage}
            onError={handleError}
          />
        )}
      </NewCustomerContainer>

      {isloading && <Loader />}
    </div>
  );
}

export default NewCustomer;
