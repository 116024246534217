import React, { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import { Tabs, Input, Form, Button, Alert, Select, Modal } from 'antd';
import { MobileOutlined, MailOutlined } from '@ant-design/icons';
import { Loader, Header } from '../../components';
import { VerficationContainer } from './styles';
import resendIcon from '../../assets/images/resend_icon.png';
import config from '../../components/configuration/config';
import countryPhoneCodes from '../../assets/json/country-phone-codes.json';

const { Option } = Select;
const { TabPane } = Tabs;

function Verfication() {
  const countdownRef = useRef(null);
  const mainCountdownRef = useRef(null);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowOtp, setIsShowOtp] = useState(false);
  const [requestOtpResp, setRequestOtpResp] = useState(null);
  const [phoneCodeSelected, setPhoneCodeSelected] = useState('63');
  const [allowSendingOtp, setAllowSendingOtp] = useState(true);
  const [mainTime, setMainTime] = useState(Date.now() + 120000);
  const [displayTimer, setDisplayTimer] = useState(false);

  const time = React.useMemo(() => {
    return Date.now() + 120000;
  }, [isShowOtp]);

  const maskEmail = (email = '') => {
    const [name, domain] = email.split('@');
    const { length: len } = name;
    const maskedName = `${name[0]}***${name[len - 1]}`;
    const maskedEmail = `${maskedName}@${domain}`;
    return maskedEmail;
  };

  const maskPhone = (number) => {
    const endDigits = number.slice(-4);
    return endDigits.padStart(number.length, '*');
  };

  const comingSoonModal = () => {
    Modal.info({
      title: 'Coming Soon.',
      onOk() {}
    });
  };

  const handleTabChange = (tab) => {
    setId('');
    setIsShowOtp(false);
    setIsPhone(tab === 'phone');
  };

  const handleCloseAlert = () => {
    setErrors(null);
  };

  const requestOtp = (isResend = false) => {
    if (isResend) {
      if (countdownRef.current) countdownRef.current.stop();
    }

    setIsLoading(true);
    setErrors(null);

    // fetch(`${config.MHC_APP_API_URL}/api/OTP/send/${isPhone}/${id}`, {
    fetch(`${config.MHC_APP_API_URL}/api/Otp/sendv1`, {
      method: 'POST',
      headers: {
        Authorization: config.MHC_APP_API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        isSMS: isPhone,
        val: isPhone ? phoneCodeSelected + id : id
      })
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setRequestOtpResp(result);
          setIsLoading(false);

          if (result.status === 'SUCCESS') {
            setOtp('');
            setIsShowOtp(true);
            setMainTime(Date.now() + 120000);
            setAllowSendingOtp(false);
            setTimeout(() => {
              setDisplayTimer(true);
              if (countdownRef.current) countdownRef.current.start();
              if (mainCountdownRef.current) mainCountdownRef.current.start();
            }, 2000);
          } else if (result.timeRemaining) {
            setMainTime(Date.now() + result.timeRemaining * 1000);
            setAllowSendingOtp(false);
            setTimeout(() => {
              setDisplayTimer(true);
              if (mainCountdownRef.current) mainCountdownRef.current.start();
            }, 100);
          } else {
            setErrors(result.message);
          }
        },
        () => {
          setRequestOtpResp(null);
          setIsLoading(false);
        }
      );
  };

  const verifyOtp = () => {
    setIsLoading(true);
    setErrors(null);

    const params = {
      id: requestOtpResp.message,
      otp
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: config.MHC_APP_API_KEY
      },
      body: JSON.stringify(params)
    };

    fetch(`${config.MHC_APP_API_URL}/api/OTP/verify`, options)
      .then((res) => res.text())
      .then(
        (result) => {
          setIsLoading(false);
          if (result === 'Invalid') {
            setOtp('');
            setErrors('The code you entered was invalid. Please try again.');
          } else if (result === 'Locked') {
            setId('');
            setOtp('');
            setIsShowOtp(false);
            setErrors('Too many attempts, please try again later.');
          } else if (result === 'Expired') {
            setOtp('');
            setErrors('OTP Expired.');
          } else {
            navigate(`/verification/orders/${result}`);
          }
        },
        () => {
          setIsLoading(false);
        }
      );
  };

  const renderCountdown = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <button type="button" onClick={() => requestOtp(true)}>
          Resend OTP
        </button>
      );
    }

    return (
      <span>
        {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
      </span>
    );
  };

  const prefixPhoneSelector = (
    <Select style={{ width: 70 }} defaultValue={phoneCodeSelected} onChange={(value) => setPhoneCodeSelected(value)}>
      {countryPhoneCodes
        .sort((a, b) => a.code?.localeCompare(b?.code))
        .map((phoneCode) => (
          <Option value={phoneCode.code} key={phoneCode.code}>
            {phoneCode.code}
          </Option>
        ))}
    </Select>
  );

  const renderMainOTPCountdown = ({ completed, minutes, seconds }) => {
    if (completed) {
      setAllowSendingOtp(true);
      setDisplayTimer(false);
      localStorage.removeItem('timeRemaining');
    } else {
      localStorage.setItem('timeRemaining', (minutes * 60 + seconds).toString());
    }

    return (
      <span>
        {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
      </span>
    );
  };

  useEffect(() => {
    const timeRemaining = localStorage.getItem('timeRemaining');
    if (timeRemaining) {
      setDisplayTimer(true);
      setAllowSendingOtp(false);
      setMainTime(Date.now() + timeRemaining * 1000);

      setTimeout(() => {
        if (mainCountdownRef.current) mainCountdownRef.current.start();
      }, 500);
    }
  }, [localStorage.getItem('timeRemaining')]);

  return (
    <VerficationContainer>
      <Header title="Security Verification" />

      <div className="content">
        <Tabs defaultActiveKey="1" onChange={handleTabChange} activeKey={isPhone ? 'phone' : 'email'}>
          <TabPane tab="Phone" key="phone" />
          <TabPane tab="Email" key="email" />
        </Tabs>

        {errors && <Alert message={errors} type="error" closable onClose={handleCloseAlert} />}

        <Form form={form} layout="vertical">
          {!isShowOtp ? (
            <>
              <Form.Item label={`Enter your ${isPhone ? `phone number` : `email address`} to receive an OTP code`}>
                {!displayTimer ? (
                  <Input
                    placeholder={isPhone ? 'Enter phone number' : 'Enter email address'}
                    prefix={isPhone ? <MobileOutlined className="site-form-item-icon" /> : <MailOutlined className="site-form-item-icon" />}
                    onChange={(e) => setId(e.target.value)}
                    value={id}
                    addonBefore={isPhone ? prefixPhoneSelector : null}
                  />
                ) : (
                  <Input
                    aria-label="otpInput"
                    placeholder={isPhone ? 'Enter phone number' : 'Enter email address'}
                    prefix={isPhone ? <MobileOutlined className="site-form-item-icon" /> : <MailOutlined className="site-form-item-icon" />}
                    addonAfter={
                      <div className="resend-btn">
                        <Countdown ref={mainCountdownRef} date={mainTime} intervalDelay={1000} autoStart={false} renderer={renderMainOTPCountdown} />
                        <img src={resendIcon} alt="Resend OTP" />
                      </div>
                    }
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                  />
                )}
              </Form.Item>
              <Button type="primary" disabled={!allowSendingOtp || !id || isLoading} block onClick={() => requestOtp(false)}>
                Request OTP
              </Button>
            </>
          ) : (
            <>
              <Form.Item label={`Enter 6-digit code sent to ${isPhone ? maskPhone(id) : maskEmail(id)}`}>
                <Input
                  aria-label="otpInput"
                  addonAfter={
                    <div className="resend-btn">
                      <Countdown ref={countdownRef} date={time} intervalDelay={1000} autoStart={false} renderer={renderCountdown} />
                      <img src={resendIcon} alt="Resend OTP" />
                    </div>
                  }
                  onChange={(e) => setOtp(e.target.value)}
                  value={otp}
                />
              </Form.Item>

              <Button type="primary" disabled={!otp || isLoading} block onClick={verifyOtp}>
                Submit
              </Button>
            </>
          )}
        </Form>
      </div>

      {isLoading && <Loader />}
    </VerficationContainer>
  );
}

export default Verfication;
