import styled from 'styled-components';

export const NotFoundContainer = styled.div`
  @media (max-width: 768px) {
    flex-direction: column;
  }

  & h3 {
    color: #060045;
    font-family: 'GTSectraDisplay';
    font-weight: 400;
    font-size: 58px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    text-align: center !important;
  }

  & div {
    font-family: 'PTRootUI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(6, 0, 69, 0.4);
    margin-block-start: 0px;
    margin-block-end: 0px;
    text-align: center !important;
  }

  & .not-found {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;
