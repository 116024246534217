import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { WelcomeMessage } from './styles';
import logo from '../../assets/images/MHC-logo.png';
import verified from '../../assets/images/verified.png';

function Verified() {
  const location = useLocation();
  return (
    <div>
      <img src={logo} className="App-logo" alt="logo" />
      {location.pathname !== '/upload' && (
        <Link to="/" className="App-Home-Link">
          Back to Home
        </Link>
      )}
      <br />
      <WelcomeMessage>
        <div>
          <img src={verified} alt="verified" className="verified" />
          <h3>All Done!</h3>
          <div>Kindly wait for our team to verify your account. You can now close this window. Thank you.</div>
        </div>
      </WelcomeMessage>
    </div>
  );
}

export default Verified;
