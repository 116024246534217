import styled from 'styled-components';

export const NewCustomerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  & div:nth-child(1) {
    width: 40%;
    margin-right: 3rem;
    text-align: left;
  }

  & div:nth-child(2) {
    width: 60%;
  }

  h1 {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #000000;
    text-align: left;
  }

  input {
    width: 100%;
    background: #ffffff;
    border: 2px solid #070342;
    box-sizing: border-box;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 18px 22px 18px 22px;
    margin-bottom: 2rem;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #817d7d;
  }

  img {
    height: 12vmin;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    & div:nth-child(1) {
      width: 100%;
      margin-right: 0px;
      text-align: center;
    }

    & div:nth-child(2) {
      width: 100%;
    }

    h1 {
      text-align: center;
      margin-bottom: 3rem;
    }
  }
`;

export const Button = styled.button`
  cursor: pointer;
  background: #070342;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  min-width: 332px;
  height: 71px;
  color: #fff;
  border-width: 0;
  font-weight: 700;
  font-size: 22px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
