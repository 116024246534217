import React from 'react';

import { Routes, Route } from 'react-router-dom';

import './index.css';
import './App.css';
import 'antd/dist/antd.min.css';

import Home from './pages/home';
import NewCustomer from './pages/new-customer';
import Verified from './pages/verified';
import Orders from './pages/orders';
import Verificaton from './pages/verification';
import NotFound from './pages/not-found';
import Upload from './pages/upload';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/new" element={<NewCustomer />} />
          <Route path="/verification" element={<Verificaton />} />
          <Route path="/verification/success" element={<Verified />} />
          <Route path="/verification/orders/:customerId" element={<Orders />} />
          <Route path="/upload/:customerId" element={<Upload />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </header>
      <footer>
        <span className="footer-span">
          <div className="footer-alignLeft">© 2022 MHC Digital Finance. All Rights Reserved.</div>
          <div className="footer-alignRight">Terms of Use | Privacy Policy</div>
        </span>
      </footer>
    </div>
  );
}

export default App;
